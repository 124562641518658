import mainjson from './main';
import { Level } from '../utils/LoginFunctions';

function checkIf(data) {
    if (parseInt(Level()) <= 0) {
        return true;
    }
    else {
        return false;
    }
}

export default function GetData() {
        let data = {
        "apitype":"clients",
        "id":"user_id",
        "options":["edit","delete","insert"],
        "nameSingle":"client",
        "nameMultiple":"clients",
        
      
        "fields":{
            "field1":{
                "name":"Name",
                "field":"user_name",
                "type":"TextInput",
                "required":true,
                "list":true
            },
            "field2":{
                "name":"Email",
                "field":"user_email",
                "type":"TextInput",
                "required":true,
                "list":true
            },
            "field3":{
                "name":"Phone",
                "field":"user_phone",
                "type":"TextInput",
                "required":true,
                "list":true
            },
            


        }
    }

    return data;
}